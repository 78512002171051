$publicPath:'';


.m-form-inline-mn[data-m="client"] {
  margin-top: 0;
  padding-top: 0;
}
/deep/.pagination-box {
  position: relative;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: flex-end;
    width: 1200px;
  }
}
.client-page {
  background-color: #fff;
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.search-box {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  & > div {
    display: flex;
    justify-content: space-between;
    width: 542px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    input {
      outline: none;
      border: none;
      flex: 1;
      text-indent: 12px;
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .btn {
      text-align: center;
      width: 98px;
      line-height: 48px;
      background: #09a666;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .table-box {
    width: 1200px;
    min-height: 630px;
    .row {
      display: flex;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 40px;
      }
      .video-card {
        &:not(:last-child) {
          margin-right: 24px;
        }
        width: 282px;
        cursor: pointer;
        height: 282px;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
          transform: scale(1.05);
        }
        .cover {
          position: relative;
          width: 282px;
          height: 165px;
          background: #f6f6f6;
          border-radius: 4px 4px 0px 0px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .time {
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: center;
            width: 72px;
            line-height: 22px;
            background: rgba(0, 0, 0, 0.7);
            color: #ffffff;
            border-radius: 3px 0px 0px 0px;
          }
        }
        .content {
          padding: 16px;
          .title {
            font-size: 20px;
            margin-bottom: 8px;
            line-height: 22px;
          }
          .tag {
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
          }
          .createTime {
            margin-top: 12px;
            display: flex;
            align-items: center;
            line-height: 20px;
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/clock-icon.png") no-repeat
                center/cover;
            }
          }
        }
      }
    }
  }
}
